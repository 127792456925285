var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (g && (g = 0, op[0] && (_ = 0)), _) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
import { findProgramByUrl } from '../../utils/find-program-by-url';
var AtlasApi = /** @class */function () {
  function AtlasApi(client) {
    this.client = client;
    this.path = 'api/atlas';
  }
  AtlasApi.prototype.getLinkInfo = function (linkUrl) {
    return __awaiter(this, void 0, Promise, function () {
      var data;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4 /*yield*/, this.client.post({
              url: "".concat(this.path, "/link-info"),
              operation: 'get-atlas-link-info',
              config: {
                data: {
                  url: linkUrl
                }
              }
            })];
          case 1:
            data = _a.sent().data;
            return [2 /*return*/, data];
        }
      });
    });
  };
  AtlasApi.prototype.createDeepLink = function (_a) {
    return __awaiter(this, arguments, Promise, function (_b) {
      var partnerId, pageTitle, _c, camref, products, creativeref, payload, data;
      var _d;
      var email = _b.email,
        url = _b.url,
        isShortLink = _b.isShortLink;
      return __generator(this, function (_e) {
        switch (_e.label) {
          case 0:
            return [4 /*yield*/, this.client.partners.getPartnerByEmail(email)];
          case 1:
            partnerId = _e.sent().partnerId;
            return [4 /*yield*/, this.getLinkInfo(url)];
          case 2:
            pageTitle = _e.sent().pageTitle;
            return [4 /*yield*/, this.findPartnerProgramByUrl(email, url)];
          case 3:
            _c = _e.sent(), camref = _c.camref, products = _c.program.products;
            creativeref = ((_d = products.find(function (product) {
              return product.name === 'quicklink';
            })) === null || _d === void 0 ? void 0 : _d.creativeRef) || '';
            payload = {
              partnerId: partnerId,
              camref: camref,
              creativeref: creativeref,
              landingPages: [{
                generateShortLink: isShortLink,
                label: pageTitle,
                // is this correct?
                //text: '', TODO: is this needed and where does it come from?
                url: url,
                type: 'custom',
                title: 'Custom Link'
              }]
            };
            return [4 /*yield*/, this.client.post({
              url: "".concat(this.path, "/urls/pz"),
              operation: 'create-deep-link',
              config: {
                data: payload
              }
            })];
          case 4:
            data = _e.sent().data;
            return [2 /*return*/, data];
        }
      });
    });
  };
  AtlasApi.prototype.findPartnerProgramByUrl = function (email, url) {
    return __awaiter(this, void 0, Promise, function () {
      var config, programs;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            config = findProgramByUrl(url);
            return [4 /*yield*/, this.client.partners.getPartnerPrograms(email)];
          case 1:
            programs = _a.sent();
            return [2 /*return*/, programs.find(function (item) {
              return item.program.id === (config === null || config === void 0 ? void 0 : config.id);
            })];
        }
      });
    });
  };
  return AtlasApi;
}();
export { AtlasApi };