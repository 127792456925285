export var supportedLocales = [/* Keep the default locale as the first element. */
{
  langId: 1033,
  langCode: 'en_US',
  langLabel: 'English (US)'
}, {
  langId: 2057,
  langCode: 'en_GB',
  langLabel: 'English (UK)'
}, {
  langId: 4105,
  langCode: 'en_CA',
  langLabel: 'English (CA)'
}, {
  langId: 3081,
  langCode: 'en_AU',
  langLabel: 'English (AU)'
}, {
  langId: 5129,
  langCode: 'en_NZ',
  langLabel: 'English (NZ)'
}, {
  langId: 18441,
  langCode: 'en_SG',
  langLabel: 'English (SG)'
}, {
  langId: 1036,
  langCode: 'fr_FR',
  langLabel: 'Français (FR)'
}, {
  langId: 3084,
  langCode: 'fr_CA',
  langLabel: 'Français (CA)'
}, {
  langId: 1031,
  langCode: 'de_DE',
  langLabel: 'Deutsch'
}, {
  langId: 2058,
  langCode: 'es_MX',
  langLabel: 'Español (MX)'
}, {
  langId: 1041,
  langCode: 'ja_JP',
  langLabel: '日本語'
}, {
  langId: 1042,
  langCode: 'ko_KR',
  langLabel: '한국어'
}, {
  langId: 1028,
  langCode: 'zh_TW',
  langLabel: '繁體中文 (TW)'
}, {
  langId: 3076,
  langCode: 'zh_HK',
  langLabel: '繁體中文 (HK)'
}, {
  langId: 2052,
  langCode: 'zh_CN',
  langLabel: '简体中文'
}, {
  langId: 1046,
  langCode: 'pt_BR',
  langLabel: 'Português (BR)'
}, {
  langId: 1035,
  langCode: 'fi_FI',
  langLabel: 'Suomi'
}, {
  langId: 1044,
  langCode: 'nb_NO',
  langLabel: 'Norsk Bokmål'
}, {
  langId: 1030,
  langCode: 'da_DK',
  langLabel: 'Dansk'
}, {
  langId: 1053,
  langCode: 'sv_SE',
  langLabel: 'Svenska'
}];