var AbritelPrograms = {
  // Order here determines order in program nav
  abritel: {
    id: 'abritel',
    name: 'Abritel',
    brand: 'Abritel',
    posa: 'FR',
    displaySiteUrl: 'abritel.fr',
    validSiteUrlPattern: '^(https?://)?(www\\.)?abritel\\.fr(?!/\\w{2}-\\w{2})(/.*)?$',
    trackingBaseUrl: 'https://vrbo.prf.hn/click'
  }
};
export { AbritelPrograms };