var HotelsPrograms = {
  // Order here determines order in program nav
  'us-hcom': {
    id: 'us-hcom',
    name: 'Hotels.com USA',
    brand: 'Hotels.com',
    posa: 'US',
    displaySiteUrl: 'hotels.com',
    validSiteUrlPattern: '^(https?://)?(www\\.)?hotels\\.com(/.*)?$',
    trackingBaseUrl: 'https://hotels.prf.hn/click'
  },
  'uk-hcom': {
    id: 'uk-hcom',
    name: 'Hotels.com UK',
    brand: 'Hotels.com',
    posa: 'GB',
    displaySiteUrl: 'uk.hotels.com',
    validSiteUrlPattern: '^(https?://)?uk\\.hotels\\.com(/.*)?$',
    trackingBaseUrl: 'https://hotels.prf.hn/click'
  },
  'ca-hcom': {
    id: 'ca-hcom',
    name: 'Hotels.com Canada',
    brand: 'Hotels.com',
    posa: 'CA',
    displaySiteUrl: 'ca.hotels.com',
    validSiteUrlPattern: '^(https?://)?ca\\.hotels\\.com(/.*)?$',
    trackingBaseUrl: 'https://hotels.prf.hn/click'
  },
  'au-hcom': {
    id: 'au-hcom',
    name: 'Hotels.com Australia',
    brand: 'Hotels.com',
    posa: 'AU',
    displaySiteUrl: 'au.hotels.com',
    validSiteUrlPattern: '^(https?://)?au\\.hotels\\.com(/.*)?$',
    trackingBaseUrl: 'https://hotels.prf.hn/click'
  },
  'fr-hcom': {
    id: 'fr-hcom',
    name: 'Hotels.com France',
    brand: 'Hotels.com',
    posa: 'FR',
    displaySiteUrl: 'fr.hotels.com',
    validSiteUrlPattern: '^(https?://)?fr\\.hotels\\.com(/.*)?$',
    trackingBaseUrl: 'https://hotels.prf.hn/click'
  },
  'mx-hcom': {
    id: 'mx-hcom',
    name: 'Hotels.com Mexico',
    brand: 'Hotels.com',
    posa: 'MX',
    displaySiteUrl: 'hoteles.com',
    validSiteUrlPattern: '^(https?://)?www\\.hoteles\\.com(/.*)?$',
    trackingBaseUrl: 'https://hotels.prf.hn/click'
  },
  'dk-hcom': {
    id: 'dk-hcom',
    name: 'Hotels.com Denmark',
    brand: 'Hotels.com',
    posa: 'DK',
    displaySiteUrl: 'da.hotels.com',
    validSiteUrlPattern: '^(https?://)?da\\.hotels\\.com(/.*)?$',
    trackingBaseUrl: 'https://hotels.prf.hn/click'
  },
  'fi-hcom': {
    id: 'fi-hcom',
    name: 'Hotels.com Finland',
    brand: 'Hotels.com',
    posa: 'FI',
    displaySiteUrl: 'fi.hotels.com',
    validSiteUrlPattern: '^(https?://)?fi\\.hotels\\.com(/.*)?$',
    trackingBaseUrl: 'https://hotels.prf.hn/click'
  },
  'no-hcom': {
    id: 'no-hcom',
    name: 'Hotels.com Norway',
    brand: 'Hotels.com',
    posa: 'NO',
    displaySiteUrl: 'no.hotels.com',
    validSiteUrlPattern: '^(https?://)?no\\.hotels\\.com(/.*)?$',
    trackingBaseUrl: 'https://hotels.prf.hn/click'
  },
  'se-hcom': {
    id: 'se-hcom',
    name: 'Hotels.com Sweden',
    brand: 'Hotels.com',
    posa: 'SE',
    displaySiteUrl: 'sv.hotels.com',
    validSiteUrlPattern: '^(https?://)?sv\\.hotels\\.com(/.*)?$',
    trackingBaseUrl: 'https://hotels.prf.hn/click'
  },
  'jp-hcom': {
    id: 'jp-hcom',
    name: 'Hotels.com Japan',
    brand: 'Hotels.com',
    posa: 'JP',
    displaySiteUrl: 'jp.hotels.com',
    validSiteUrlPattern: '^(https?://)?jp\\.hotels\\.com(/.*)?$',
    trackingBaseUrl: 'https://hotels.prf.hn/click'
  },
  'kr-hcom': {
    id: 'kr-hcom',
    name: 'Hotels.com South Korea',
    brand: 'Hotels.com',
    posa: 'KR',
    displaySiteUrl: 'kr.hotels.com',
    validSiteUrlPattern: '^(https?://)?kr\\.hotels\\.com(/.*)?$',
    trackingBaseUrl: 'https://hotels.prf.hn/click'
  },
  'br-hcom': {
    id: 'br-hcom',
    name: 'Hotels.com Brazil',
    brand: 'Hotels.com',
    posa: 'BR',
    displaySiteUrl: 'hoteis.com',
    validSiteUrlPattern: '^(https?://)?(www\\.)?hoteis\\.com(/.*)?$',
    trackingBaseUrl: 'https://hotels.prf.hn/click'
  },
  'tw-hcom': {
    id: 'tw-hcom',
    name: 'Hotels.com Taiwan',
    brand: 'Hotels.com',
    posa: 'TW',
    displaySiteUrl: 'tw.hotels.com',
    validSiteUrlPattern: '^(https?://)?tw\\.hotels\\.com(/.*)?$',
    trackingBaseUrl: 'https://hotels.prf.hn/click'
  }
  // Disabling temporarily as contents is not ready
  // 'is-hcom': {
  //   id: 'is-hcom',
  //   name: 'Hotels.com Iceland',
  //   brand: 'Hotels.com',
  //   displaySiteUrl: 'is.hotels.com',
  //   validSiteUrlPattern: '^(https?://)?is\\.hotels\\.com(/.*)?$',
  //   trackingBaseUrl: 'https://hotels.prf.hn/click',
  // },
};
export { HotelsPrograms };