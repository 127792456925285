var ExpediaPrograms = {
  // Order here determines order in program nav
  'us-expedia': {
    id: 'us-expedia',
    name: 'Expedia USA',
    brand: 'Expedia',
    posa: 'US',
    displaySiteUrl: 'expedia.com',
    validSiteUrlPattern: '^(https?://)?(www\\.)?expedia\\.com(/.*)?$',
    trackingBaseUrl: 'https://prf.hn/click'
  },
  'uk-expedia': {
    id: 'uk-expedia',
    name: 'Expedia UK',
    brand: 'Expedia',
    posa: 'GB',
    displaySiteUrl: 'expedia.co.uk',
    validSiteUrlPattern: '^(https?://)?(www\\.)?expedia\\.co.uk(/.*)?$',
    trackingBaseUrl: 'https://prf.hn/click'
  },
  'ca-expedia': {
    id: 'ca-expedia',
    name: 'Expedia CA',
    brand: 'Expedia',
    posa: 'CA',
    displaySiteUrl: 'expedia.ca',
    validSiteUrlPattern: '^(https?://)?(www\\.)?expedia\\.ca(/.*)?$',
    trackingBaseUrl: 'https://prf.hn/click'
  },
  'au-expedia': {
    id: 'au-expedia',
    name: 'Expedia AU',
    brand: 'Expedia',
    posa: 'AU',
    displaySiteUrl: 'expedia.com.au',
    validSiteUrlPattern: '^(https?://)?(www\\.)?expedia\\.com.au(/.*)?$',
    trackingBaseUrl: 'https://prf.hn/click'
  },
  'fr-expedia': {
    id: 'fr-expedia',
    name: 'Expedia FR',
    brand: 'Expedia',
    posa: 'FR',
    displaySiteUrl: 'expedia.fr',
    validSiteUrlPattern: '^(https?://)?(www\\.)?expedia\\.fr(/.*)?$',
    trackingBaseUrl: 'https://prf.hn/click'
  },
  'de-expedia': {
    id: 'de-expedia',
    name: 'Expedia DE',
    brand: 'Expedia',
    posa: 'DE',
    displaySiteUrl: 'expedia.de',
    validSiteUrlPattern: '^(https?://)?(www\\.)?expedia\\.de(/.*)?$',
    trackingBaseUrl: 'https://prf.hn/click'
  },
  'mx-expedia': {
    id: 'mx-expedia',
    name: 'Expedia MX',
    brand: 'Expedia',
    posa: 'MX',
    displaySiteUrl: 'expedia.mx',
    validSiteUrlPattern: '^(https?://)?(www\\.)?expedia\\.mx(/.*)?$',
    trackingBaseUrl: 'https://prf.hn/click'
  },
  'jp-expedia': {
    id: 'jp-expedia',
    name: 'Expedia JP',
    brand: 'Expedia',
    posa: 'JP',
    displaySiteUrl: 'expedia.co.jp',
    validSiteUrlPattern: '^(https?://)?(www\\.)?expedia\\.co.jp(/.*)?$',
    trackingBaseUrl: 'https://prf.hn/click'
  },
  'kr-expedia': {
    id: 'kr-expedia',
    name: 'Expedia KR',
    brand: 'Expedia',
    posa: 'KR',
    displaySiteUrl: 'expedia.co.kr',
    validSiteUrlPattern: '^(https?://)?(www\\.)?expedia\\.co.kr(/.*)?$',
    trackingBaseUrl: 'https://prf.hn/click'
  },
  'hk-expedia': {
    id: 'hk-expedia',
    name: 'Expedia HK',
    brand: 'Expedia',
    posa: 'HK',
    displaySiteUrl: 'expedia.com.hk',
    validSiteUrlPattern: '^(https?://)?(www\\.)?expedia\\.com.hk(/.*)?$',
    trackingBaseUrl: 'https://prf.hn/click'
  },
  'sg-expedia': {
    id: 'sg-expedia',
    name: 'Expedia SG',
    brand: 'Expedia',
    posa: 'SG',
    displaySiteUrl: 'expedia.com.sg',
    validSiteUrlPattern: '^(https?://)?(www\\.)?expedia\\.com.sg(/.*)?$',
    trackingBaseUrl: 'https://prf.hn/click'
  },
  'nz-expedia': {
    id: 'nz-expedia',
    name: 'Expedia NZ',
    brand: 'Expedia',
    posa: 'NZ',
    displaySiteUrl: 'expedia.co.nz',
    validSiteUrlPattern: '^(https?://)?(www\\.)?expedia\\.co.nz(/.*)?$',
    trackingBaseUrl: 'https://prf.hn/click'
  },
  'global-influencers-expedia': {
    id: 'global-influencers-expedia',
    name: 'Global Influencers Expedia',
    brand: 'Expedia',
    posa: 'GLOBAL',
    // The next properties have mock values to stop deeplink builder using it
    displaySiteUrl: 'global-influencers.expedia.com',
    validSiteUrlPattern: '^(https?://)?(www\\.)?global-influencers.expedia\\.com(/.*)?$',
    trackingBaseUrl: 'https://prf.hn/click'
  }
};
export { ExpediaPrograms };