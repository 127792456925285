var StayzPrograms = {
  // Order here determines order in program nav
  'au-stayz': {
    id: 'au-stayz',
    name: 'Stayz Australia',
    brand: 'Stayz',
    posa: 'AU',
    displaySiteUrl: 'stayz.com.au',
    validSiteUrlPattern: '^(https?://)?(www\\.)?stayz\\.com\\.au(/.*)?$',
    trackingBaseUrl: 'https://vrbo.prf.hn/click'
  }
};
export { StayzPrograms };