var FewoDirektPrograms = {
  // Order here determines order in program nav
  'fewo-direkt': {
    id: 'fewo-direkt',
    name: 'FeWo-direkt',
    brand: 'FeWo-direkt',
    posa: 'DE',
    displaySiteUrl: 'fewo-direkt.de',
    validSiteUrlPattern: '^(https?://)?(www\\.)?fewo-direkt\\.de(?!/\\w{2}-\\w{2})(/.*)?$',
    trackingBaseUrl: 'https://vrbo.prf.hn/click'
  }
};
export { FewoDirektPrograms };