var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
import { AbritelPrograms } from './abritel-program.config';
import { ExpediaPrograms } from './expedia-program.config';
import { FewoDirektPrograms } from './fewo-direkt-program.config';
import { HotelsPrograms } from './hotels-program.config';
import { StayzPrograms } from './stayz-program.config';
import { VrboPrograms } from './vrbo-program.config';
import { WotifPrograms } from './wotif-program.config';
import { BookabachPrograms } from './bookabach-program.config';
// TODO: this seems like it should come from the server
var ProgramsConfig = __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, ExpediaPrograms), HotelsPrograms), VrboPrograms), AbritelPrograms), BookabachPrograms), FewoDirektPrograms), StayzPrograms), WotifPrograms);
export { ProgramsConfig, ExpediaPrograms, HotelsPrograms, VrboPrograms, AbritelPrograms, BookabachPrograms, FewoDirektPrograms, StayzPrograms, WotifPrograms };
export * from './program-id-by-country.config';