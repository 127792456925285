var BookabachPrograms = {
  // Order here determines order in program nav
  'nz-bookabach': {
    id: 'nz-bookabach',
    name: 'Bookabach NZ',
    brand: 'Bookabach',
    posa: 'NZ',
    displaySiteUrl: 'bookabach.co.nz',
    validSiteUrlPattern: '^(https?://)?(www\\.)?bookabach\\.co.nz(?!/\\w{2}-\\w{2})(/.*)?$',
    trackingBaseUrl: 'https://vrbo.prf.hn/click'
  }
};
export { BookabachPrograms };