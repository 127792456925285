var WotifPrograms = {
  'au-wotif': {
    id: 'au-wotif',
    name: 'Wotif AU',
    brand: 'Wotif',
    posa: 'AU',
    displaySiteUrl: 'wotif.com',
    validSiteUrlPattern: '^(https?://)?(www\\.)?wotif\\.com(?!/\\w{2}-\\w{2})(/.*)?$',
    trackingBaseUrl: 'https://prf.hn/click'
  }
};
export { WotifPrograms };