var VrboPrograms = {
  // Order here determines order in program nav
  'us-vrbo': {
    id: 'us-vrbo',
    name: 'Vrbo USA',
    brand: 'Vrbo',
    posa: 'US',
    displaySiteUrl: 'vrbo.com',
    validSiteUrlPattern: '^(https?://)?(www\\.)?vrbo\\.com(?!/\\w{2}-\\w{2})(/.*)?$',
    trackingBaseUrl: 'https://vrbo.prf.hn/click'
  },
  'uk-vrbo': {
    id: 'uk-vrbo',
    name: 'Vrbo UK',
    brand: 'Vrbo',
    posa: 'GB',
    displaySiteUrl: 'vrbo.com/en-gb',
    validSiteUrlPattern: '^(https?://)?(www\\.)?vrbo\\.com/en-gb(/.*)?$',
    trackingBaseUrl: 'https://vrbo.prf.hn/click'
  },
  'ca-vrbo': {
    id: 'ca-vrbo',
    name: 'Vrbo Canada',
    brand: 'Vrbo',
    posa: 'CA',
    displaySiteUrl: 'vrbo.com/en-ca vrbo.com/fr-ca',
    validSiteUrlPattern: '^(https?://)?(www\\.)?vrbo\\.com/(en-ca|fr-ca)(/.*)?$',
    trackingBaseUrl: 'https://vrbo.prf.hn/click'
  }
};
export { VrboPrograms };