import { ProgramsConfig } from '../config/programs';
var config = ProgramsConfig;
export function findProgramByUrl(url) {
  for (var key in config) {
    var program = config[key];
    var regex = new RegExp(program.validSiteUrlPattern);
    if (regex.test(url)) {
      return program;
    }
  }
  return null;
}